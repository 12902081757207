// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-wood-bonsai-table-2-2020-js": () => import("./../src/pages/projects/wood/bonsai-table-2-2020.js" /* webpackChunkName: "component---src-pages-projects-wood-bonsai-table-2-2020-js" */),
  "component---src-pages-projects-wood-cutting-board-2-2020-js": () => import("./../src/pages/projects/wood/cutting-board-2-2020.js" /* webpackChunkName: "component---src-pages-projects-wood-cutting-board-2-2020-js" */),
  "component---src-pages-projects-wood-entertainment-center-1-2020-js": () => import("./../src/pages/projects/wood/entertainment-center-1-2020.js" /* webpackChunkName: "component---src-pages-projects-wood-entertainment-center-1-2020-js" */),
  "component---src-pages-projects-wood-shoe-shelves-2-2020-js": () => import("./../src/pages/projects/wood/shoe-shelves-2-2020.js" /* webpackChunkName: "component---src-pages-projects-wood-shoe-shelves-2-2020-js" */),
  "component---src-pages-roadmap-js": () => import("./../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-tools-js": () => import("./../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */)
}

